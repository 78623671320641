import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je biodiverzita?" />
    <h1>Co je biodiverzita?</h1>
    <p>Biodiverzita označuje ve&scaron;kerou rozmanitost živ&eacute; př&iacute;rody od genetick&eacute; &uacute;rovně až po cel&eacute; ekosyst&eacute;my. Biologick&aacute; rozmanitost zaji&scaron;ťuje vy&scaron;&scaron;&iacute; stabilitu a lep&scaron;&iacute; fungov&aacute;n&iacute; ekosyst&eacute;mu. V bohat&scaron;&iacute;ch společenstvech se druhy dok&aacute;ž&iacute; l&eacute;pe zastupovat a vyhynut&iacute; jednoho či v&iacute;ce druhů nezpůsob&iacute; kolaps dan&eacute;ho ekosyst&eacute;mu.</p>
    <p>Důležitost biodiverzity lze uk&aacute;zat na př&iacute;kladu kůrovcov&eacute; kalamity v ČR. Pokud by byly lesy v&iacute;cedruhov&eacute;, byla zde př&iacute;tomn&aacute; vy&scaron;&scaron;&iacute; biodiverzita, přemnožen&iacute; jednoho &scaron;kůdce by nezpůsobovalo kolaps cel&yacute;ch lesn&iacute;ch ekosyst&eacute;mů.</p>
    <hr />
    <h2>Měřen&iacute; biodiverzity</h2>
    <p>Biodiverzitu lze měřit mnoha různ&yacute;mi způsoby, z&aacute;lež&iacute;, kterou &uacute;rovn&iacute; se při jej&iacute;m měřen&iacute; zab&yacute;v&aacute;me:</p>
    <ul>
    <li><strong>taxonomick&aacute; biodiverzita</strong> (nejčastěj&scaron;&iacute;, podle počtu druhů či jin&yacute;ch taxonů)</li>
    <li><strong>genetick&aacute; diverzita</strong> (dle sekvence DNA)</li>
    <li><strong>funkčn&iacute; diverzita</strong> (dle variability vlastnost&iacute; organismů)</li>
    <li><strong>fylogenetick&aacute; diverzita</strong> (dle rozmanitosti evolučn&iacute;ch lini&iacute;)</li>
    <li><strong>krajinn&aacute; diverzita</strong> (dle spektr&aacute;ln&iacute; variability zeleně z družicov&yacute;ch sn&iacute;mků)</li>
    <li><strong>syntaxonomick&aacute; diverzita</strong> (dle počtu společenstev)</li>
    </ul>
    <hr />
    <h2>Typy biodiverzity</h2>
    <p>Ekolog Whittaker rozli&scaron;il v roce 1960 tři typy biodiverzity, a to:</p>
    <ul>
    <li><strong>alfa diverzita</strong> (dle počtu druhů na lokalitě či sledovan&eacute;m vzorku, např. uvnitř mapovac&iacute;ho kvadr&aacute;tu či v jednom fytocenologick&eacute;m sn&iacute;mku)</li>
    <li><strong>beta diverzita</strong> (dle rozd&iacute;lu v druh&eacute;m složen&iacute; mezi lokalitami, různ&eacute;ho počtu společenstev či stanovi&scaron;ť ve sledovan&eacute; krajině)</li>
    <li><strong>gama diverzita</strong> (dle počtu druhů v &scaron;ir&scaron;&iacute; lokalitě a krajině)</li>
    </ul>
    <p>Gama diverzita se často prol&iacute;n&aacute; se <strong>species pool</strong> (tj. druhov&yacute; z&aacute;sobn&iacute;k), kter&yacute; představuje množinu druhů potenci&aacute;lně schopn&yacute;ch os&iacute;dlit danou lokalitu. Ne v&scaron;echny druhy ze species pool jsou v&scaron;ak gama diverzitou (nemus&iacute; b&yacute;t skutečně př&iacute;tomny v &scaron;ir&scaron;&iacute;m okol&iacute;) a ne v&scaron;echny druhy gama diverzity jsou species pool (nemus&iacute; b&yacute;t potencii&aacute;lně schopn&eacute; os&iacute;dlit danou lokalitu).</p>
    <p>Beta diverzita může b&yacute;t vyj&aacute;dřena pod&iacute;lem gama diverzity a průměrn&eacute; alfa diverzity.</p>
    <h3>Alfa diverzita</h3>
    <p>Alfa diverzita popisuje biodiverzitu v jedn&eacute; lokalitě či v dan&eacute;m, sledovan&eacute;m vzorku. Nejjednodu&scaron;&scaron;&iacute;m způsobem jak vyj&aacute;dřit alfa diverzitu je prost&yacute; počet druhů na stanovi&scaron;ti.</p>
    <p>Často je ale tak&eacute; vyjadřov&aacute;na indexy alfa diverzity, kter&eacute; kombinuj&iacute; počet druhů a dal&scaron;&iacute; ukazatele, jako je např. abundance či fylogenetick&aacute; př&iacute;buznost, do jednoho č&iacute;sla:</p>
    <ul>
    <li><strong>Shannonův index diverzity</strong></li>
    <li><strong>Simpsonův index diverzity</strong></li>
    <li><strong>Rao koeficient diverzity</strong></li>
    </ul>
    <h4>Simpsonův index diverzity</h4>
    <p>Simpsonův index diverzity vyjadřuje pravděpodobnost, že dva n&aacute;hodně vybran&iacute; jedinci budou patřit ke stejn&eacute;mu druhu. Jde o jeden z nejlep&scaron;&iacute;ch indexů biodiverzity, alespoň co se t&yacute;k&aacute; interpretace. Zahrnuje relativn&iacute; abundanci druhů a počet druhů ve společenstvu.</p>
    <p>Se zvy&scaron;uj&iacute;c&iacute; hodnotou indexu kles&aacute; diverzita, proto se častěji použ&iacute;v&aacute; komplement&aacute;rn&iacute; (1-D = Gini-Simpso index) či reciprok&aacute; forma indexu (1/D).</p>
    <h4>Shannonův index diverzity</h4>
    <p>Shannonův index diverzity (či Shannon-Wiener index) je nejpouž&iacute;vaněj&scaron;&iacute;m indexem biodiverzity. Je odvozen z informačn&iacute; teorie, konkr&eacute;tně z entropie syst&eacute;mu, kdy se poč&iacute;t&aacute; růst neuspoř&aacute;danosti syst&eacute;mu spolu s rostouc&iacute; entropi&iacute;. Byly vybr&aacute;ny stejn&eacute; veličiny, abundance druhů a počet druhů ve společenstvu, ale d&iacute;ky jin&eacute;mu uspoř&aacute;d&aacute;n&iacute; vzorce z&aacute;vid&iacute; hodnota předev&scaron;&iacute;m na vyv&aacute;ženosti abundanc&iacute; druhů.</p>
    <p>Hodnota indexu stoup&aacute; s počtem druhů a předev&scaron;&iacute;m vyrovnanost&iacute; jejich abundanc&iacute;. Při absolutn&iacute; vyrovnanosti abundanc&iacute; je rovna počtu druhů. Při absolutn&iacute; dominanci jednoho druhu dosahuje nuly. Obvykle se pohybuje mezi 1,5&nbsp;a&nbsp;3,5.</p>
    <h4>Rao index diverzity</h4>
    <p>Rao index diverzity zahrnuje krom abundance a počtu druhů ve společenstvu i jejich vz&aacute;jemnou fylogenetickou. Ta se poč&iacute;t&aacute; jako taxonomick&aacute; vzd&aacute;lenost na fylogenetick&eacute;m stromu.</p>
    <p>Hodnota indexu stoup&aacute; s počtem druhů, jejich abundancemi a vy&scaron;&scaron;&iacute; diverzitou druhů z různ&yacute;ch fylogenetick&yacute;ch evolučn&iacute;ch lini&iacute;.</p>
    <h3>Beta diverzita</h3>
    <p>Beta diverzita popisuje rozd&iacute;lnost druhov&eacute;ho složen&iacute; mezi vzorky, rozli&scaron;ujeme dva z&aacute;kladn&iacute; typy beta biodiverzity:</p>
    <ul>
    <li><strong>species turnover</strong> (obměna druhů pod&eacute;l ekologick&eacute;ho, prostorov&eacute;ho či časov&eacute;ho gradientu)</li>
    <li><strong>variation</strong> (variabilita v druh&eacute;m složen&iacute; mezi vzorky bez ohledu na směr jak&eacute;hokoliv gradientu)</li>
    </ul>
    <h4>Měřen&iacute; beta diverzity klasick&yacute;mi indexy</h4>
    <p>Měřen&iacute; beta diverzity pomoc&iacute; klasick&yacute;ch indexů je nejjednodu&scaron;&scaron;&iacute;m způsobem. Neberou v potaz druhov&eacute; složen&iacute; a poč&iacute;taj&iacute; biodiverzitu z počtu druhů na lok&aacute;ln&iacute; (alfa d.) a region&aacute;ln&iacute; (gama d.) &uacute;rovni.</p>
    <p>Mezi běžn&eacute; klasick&eacute; indexy patř&iacute;:</p>
    <ul>
    <li><strong>Whittakerova beta diverzita</strong> (multiplikativn&iacute; m&iacute;ra, od pod&iacute;lu gama diverzity a průměrn&eacute; alfa diverzity se odečte jednička)</li>
    <li><strong>Aditivn&iacute; m&iacute;ra beta diverzity</strong> (od gama diverzity se odečte průměrn&aacute; alfa diverzita)</li>
    <li><strong>Shannonova beta diverzita</strong> (multiplikativn&iacute; m&iacute;ra, kter&aacute; bere v potaz vyrovnanost, rovn&aacute; se pod&iacute;lu Shannonova indexu pro gama a alfa diverzitu)</li>
    </ul>
    <h4>Měřen&iacute; beta diverzity mnohorozměrn&yacute;mi indexy</h4>
    <p>Mnohorozměrn&eacute; indexy pracuj&iacute; s druhov&yacute;m složen&iacute;m p&aacute;rů vzorků a měř&iacute;:</p>
    <ul>
    <li>podobnost druhov&eacute;ho složen&iacute;, pot&eacute; mluv&iacute;me o <strong>indexech podobnosti (S)</strong> a měřen&iacute; tzv. <strong>ekologick&eacute; podobnosti</strong>
    <ul>
    <li>nejniž&scaron;&iacute; hodnota 0 = vzorky nesd&iacute;l&iacute; ž&aacute;dn&yacute; druh</li>
    </ul>
    </li>
    <li>rozd&iacute;lnost druhov&eacute;ho složen&iacute;, pot&eacute; jde o <strong>indexy vzd&aacute;lenosti či nepodobnosti (D nebo beta)</strong> a měřen&iacute; <strong>ekologick&eacute; vzd&aacute;lenosti</strong> či pr&aacute;vě <strong>beta diverzity</strong>
    <ul>
    <li>nejniž&scaron;&iacute; hodnota 0 = vzorky jsou shodn&eacute;, sd&iacute;l&iacute; v&scaron;echny druhy</li>
    </ul>
    </li>
    </ul>
    <p>Velk&aacute; č&aacute;st indexů měř&iacute; pr&aacute;vě ekologickou podobnost. Pokud se pohybuj&iacute; v rozměz&iacute; 1 a 0, často se na nepodobnosti či vzd&aacute;lenosti přev&aacute;d&iacute; odečten&iacute;m od jedničky. Ne v&scaron;echny indexy lze ale takto konvertovat.</p>
    <h5>Principy mnohorozměrn&yacute;ch indexů</h5>
    <p>Mnohorozměrn&eacute; indexy vych&aacute;z&iacute; v naprost&eacute; vět&scaron;ině z n&aacute;sleduj&iacute;c&iacute;ch komponentů:</p>
    <ul>
    <li>a = počet druhů př&iacute;tomen v obou vzorc&iacute;ch</li>
    <li>b, c = počet druhů v jednom vzorku</li>
    <li>d = počet druhů, kter&eacute; chyb&iacute; v obou vzorc&iacute;ch, ale jsou př&iacute;tomny v jin&yacute;ch v r&aacute;mci zkouman&eacute;ho datasetu</li>
    </ul>
    <p>Indexy se děl&iacute; podle (ne)zahrnut&iacute; komponentu d na:</p>
    <ul>
    <li>symetrick&eacute; indexy (poč&iacute;taj&iacute; s dvojitou nepř&iacute;tomnost&iacute; a do v&yacute;počtu zahrnuj&iacute; komponentu d)</li>
    <li>asymetrick&eacute; indexy (nezahrnut&iacute; komponenty d)</li>
    </ul>
    <p>Podle typu vstupn&iacute;ch dat je pak děl&iacute;me na:</p>
    <ul>
    <li>kvalitativn&iacute; indexy (pouze pro prezenčně-absenčn&iacute; data, ANO/NE)</li>
    <li>kvantitativn&iacute; indexy (pro data vyjadřuj&iacute;c&iacute; abundance, počty aj.)</li>
    </ul>
    <hr />
    <h2>Biodiverzita a samostatn&aacute; t&eacute;mata</h2>
    <p>Biodiverzita je rozs&aacute;hl&eacute; t&eacute;ma, a to ikdyž jde o stručn&eacute; z&aacute;pisky ve studijn&iacute;ch materi&aacute;lech. Proto byla vybran&aacute; t&eacute;mata vyčleněna do samostatn&yacute;ch podkapitol:</p>
<div className="od-sm"><hr />
    <h3><Link to="/biogeografie/biodiverzita/globalni-druhove-bohatstvi/">Glob&aacute;ln&iacute; druhov&aacute; diverzita</Link></h3>
    <p>Pohled na glob&aacute;ln&iacute; druhovou bohatost a na nejn&aacute;padněj&scaron;&iacute; jev glob&aacute;ln&iacute; biodiverzity - latitudin&aacute;ln&iacute; gradient diverzity (LGD)</p>
<hr />
    <h3><Link to="/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy/">SAR: Vztah diverzity a&nbsp;plochy</Link></h3>
    <p>Vztah druhov&eacute; diverzity a plochy (SAR) jde vyj&aacute;dřit jako matematick&aacute; funkce. A ta m&aacute; v z&aacute;vislosti na prostorov&eacute;m měř&iacute;tku různ&yacute;, tř&iacute;f&aacute;zov&yacute; průběh.</p>
<hr />
    <h3><Link to="/biogeografie/biodiverzita/lokalni-druhove-bohatstvi/">Lok&aacute;ln&iacute; druhov&aacute; diverzita</Link></h3>
    <p>Jak je na tom lok&aacute;ln&iacute; druhov&eacute; bohatstv&iacute;? Jednoduch&yacute; přehled, jak&eacute; faktory ovlivňuj&iacute; lok&aacute;ln&iacute; biodiverzitu nejv&yacute;razněji.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Biodiverzita (druhová bohatost)</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/spolecenstva/vegetace-evropy/"><button className="inv">&larr; Vegetace Evropy (společenstva)</button></Link>
    <Link to="/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy/"><button className="inv">SAR: Vztah diverzity a plochy &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
